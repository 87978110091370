var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"py-2 border-top company-documents"},[_c('drag-n-drop-upload',{attrs:{"uploadType":"customer","activeRole":"ROLE_ADMIN"},on:{"fetchDocuments":_vm.fetchAll}}),_c('div',{staticClass:"company-documents__table mb-5"},[_c('v-card',{staticClass:"company-documents__table-card",class:{ 'v-card--no-border': _vm.$vuetify.breakpoint.smAndDown }},[_c('v-data-table',{staticClass:"text-no-wrap",attrs:{"headers":_vm.headers,"items":_vm.loadedDocuments,"server-items-length":_vm.totalLoadedDocuments,"mobile-breakpoint":0,"hide-default-footer":true,"loading":_vm.loading,"footer-props":{
          'items-per-page-options': [15, 30, 50, 100],
          'items-per-page-text': _vm.$t('myBavDocumentsPerPage'),
        }},on:{"update:items-per-page":_vm.setLimit,"pagination":_vm.handlePagination},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
        var item = ref.item;
return [_c('span',{staticClass:"text-no-wrap th",attrs:{"title":item.name}},[_vm._v(_vm._s(item.name))])]}},{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-center pr-0"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-6",attrs:{"size":"16","color":"primary"},on:{"click":function($event){return _vm.download(item.id)}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.icons.mdiDownload)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('download')))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-0",attrs:{"size":"16","color":"primary"},on:{"click":function($event){return _vm.onDelete(item.id)}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.icons.mdiDelete)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('delete')))])])],1)]}}],null,true)},[_c('template',{slot:"no-data"},[_c('div',{staticClass:"my-5"},[_c('p',[_vm._v(_vm._s(_vm.$t('myBavNoResults')))])])])],2)],1)],1),_c('ModalWindow',{attrs:{"is-open":_vm.deleteDocumentsModal,"error":""},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('p',{staticClass:"text-base"},[_vm._v(_vm._s(_vm.$t('myBavPermanentDeletionConfirmation')))])]},proxy:true},{key:"actions",fn:function(){return [_c('v-btn',{attrs:{"outlined":"","color":"primary"},on:{"click":function($event){_vm.deleteDocumentsModal = false}}},[_vm._v(" "+_vm._s(_vm.$t('buttonCancel'))+" ")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.confirmDelete}},[_vm._v(" "+_vm._s(_vm.$t('buttonConfirm'))+" ")])]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
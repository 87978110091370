<template>
  <div class="py-2 border-top company-documents">
    <drag-n-drop-upload uploadType="customer" activeRole="ROLE_ADMIN" @fetchDocuments="fetchAll" />
    <div class="company-documents__table mb-5">
      <v-card class="company-documents__table-card" :class="{ 'v-card--no-border': $vuetify.breakpoint.smAndDown }">
        <v-data-table
          :headers="headers"
          :items="loadedDocuments"
          :server-items-length="totalLoadedDocuments"
          :mobile-breakpoint="0"
          :hide-default-footer="true"
          :loading="loading"
          :footer-props="{
            'items-per-page-options': [15, 30, 50, 100],
            'items-per-page-text': $t('myBavDocumentsPerPage'),
          }"
          @update:items-per-page="setLimit"
          @pagination="handlePagination"
          class="text-no-wrap"
        >
          <template #[`item.name`]="{ item }">
            <span class="text-no-wrap th" :title="item.name">{{ item.name }}</span>
          </template>

          <!-- actions -->
          <template #[`item.actions`]="{ item }">
            <div class="d-flex justify-center pr-0">
              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <v-icon size="16" class="mr-6" color="primary" v-bind="attrs" v-on="on" @click="download(item.id)">
                    {{ icons.mdiDownload }}
                  </v-icon>
                </template>
                <span>{{ $t('download') }}</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <v-icon size="16" class="mr-0" color="primary" v-bind="attrs" v-on="on" @click="onDelete(item.id)">
                    {{ icons.mdiDelete }}
                  </v-icon>
                </template>
                <span>{{ $t('delete') }}</span>
              </v-tooltip>
            </div>
          </template>

          <template slot="no-data">
            <div class="my-5">
              <p>{{ $t('myBavNoResults') }}</p>
            </div>
          </template>
        </v-data-table>
      </v-card>
    </div>
    <ModalWindow :is-open="deleteDocumentsModal" error>
      <template #content>
        <p class="text-base">{{ $t('myBavPermanentDeletionConfirmation') }}</p></template
      >
      <template #actions>
        <v-btn outlined color="primary" @click="deleteDocumentsModal = false">
          {{ $t('buttonCancel') }}
        </v-btn>
        <v-btn color="primary" @click="confirmDelete">
          {{ $t('buttonConfirm') }}
        </v-btn>
      </template>
    </ModalWindow>
  </div>
</template>

<script>
import { ref, computed, getCurrentInstance } from '@vue/composition-api';
import { mdiDownload, mdiMagnify, mdiDelete, mdiDotsVertical } from '@mdi/js';
import {
  getCustomerInfoDocuments,
  uploadCustomerInfoDocument,
  downloadDocument,
  deleteDocument,
} from '../../../api/document';
import DragNDropUpload from '@/components/upload/DragNDropUpload';
import ModalWindow from '@/components/modal/ModalWindow';

export default {
  name: 'CustomerInfoDocuments',
  components: { DragNDropUpload, ModalWindow },
  setup() {
    const vm = getCurrentInstance().proxy;
    const loadedDocuments = ref([]);
    const totalLoadedDocuments = ref(0);
    const loading = ref(false);
    const deleteDocumentsModal = ref(false);
    const documentToDelete = ref(null);
    const limit = ref(15);
    const offset = ref(0);
    const currentPage = ref(1);
    const searchQuery = ref('');

    const headers = computed(() => {
      return [
        {
          text: vm.$t('tableHeaderDocuments'),
          value: 'name',
          sortable: true,
          class: 'text-uppercase',
          width: '70%',
        },
        {
          text: vm.$t('myBavActions'),
          value: 'actions',
          align: 'center',
          sortable: false,
          class: 'text-uppercase',
          width: '2%',
        },
      ];
    });

    const fetchAll = () => {
      loading.value = true;
      getCustomerInfoDocuments(offset.value, limit.value, searchQuery.value).then((res) => {
        loadedDocuments.value = res.data.page;
        loading.value = false;
        totalLoadedDocuments.value = res.data.total;
      });
    };

    const download = (id) => {
      downloadDocument(id);
    };

    const handlePagination = (event) => {
      if (currentPage.value < event.page) offset.value += limit.value;
      if (currentPage.value > event.page) offset.value -= limit.value;

      currentPage.value = event.page;
      fetchAll();
    };

    const setLimit = (v) => {
      limit.value = v;
      currentPage.value = 1;
      offset.value = 0;
      fetchAll();
    };

    const onDelete = (id) => {
      deleteDocumentsModal.value = true;
      documentToDelete.value = id;
    };

    const confirmDelete = () => {
      deleteDocument(documentToDelete.value).then(() => {
        deleteDocumentsModal.value = false;
        documentToDelete.value = null;

        fetchAll();
      });
    };

    return {
      download,
      offset,
      currentPage,
      searchQuery,
      limit,
      loading,
      totalLoadedDocuments,
      loadedDocuments,
      deleteDocumentsModal,
      headers,
      handlePagination,
      setLimit,
      onDelete,
      confirmDelete,
      fetchAll,
      icons: {
        mdiDownload,
        mdiMagnify,
        mdiDelete,
        mdiDotsVertical,
      },
    };
  },
};
</script>

<template>
  <div class="py-5 border-top">
    <v-card>
      <v-card-text>
        <h2>{{ $t('Add new provider') }}</h2>
      </v-card-text>
      <v-card-text>
        <v-form ref="addProviderForm" v-model="isFormValid" @submit.prevent="addNewHealthInsuranceProvider()">
          <v-row class="justify-center px-3">
            <v-col cols="12" md="4" class="px-0">
              <v-text-field
                v-model="newHealthProvider"
                :label="$t('Provider name')"
                :placeholder="$t('Provider name')"
                :rules="[validators.required]"
                class="me-3 mb-0"
                dense
                outlined
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4" class="px-0">
              <v-text-field
                v-model="newHealthProviderContribution"
                type="number"
                min="0"
                oninput="if(this.value < 0) this.value = 0;"
                step="0.01"
                :label="$t('Provider contribution')"
                :placeholder="$t('Provider contribution')"
                :rules="[validators.numberRequired]"
                class="me-3 mb-0"
                dense
                outlined
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4" class="px-0">
              <v-btn :disabled="creatingNewHealthProvider" type="submit" color="primary" class="me-3">
                {{ $t('Add') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
    <div class="mt-2">
      <div class="pb-5">
        <div class="my-bav__table mb-5">
          <v-card>
            <v-card-text>
              <h2>{{ $t('myBavSearchNFilter') }}</h2>
            </v-card-text>
            <v-card-text class="d-flex align-center flex-wrap pb-0">
              <v-row class="px-3">
                <v-col cols="12" md="4" class="px-0">
                  <v-text-field
                    v-model="searchQuery"
                    single-line
                    dense
                    outlined
                    hide-details
                    clearable
                    :label="$t('formPlaceholderSearch')"
                    :placeholder="$t('formPlaceholderSearch')"
                    class="mb-5 me-3"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>

            <v-data-table
              :headers="headers"
              :items="loadedHealthProviders"
              :server-items-length="totalLoadedHealthProviders"
              :loading="loadingHealthProviders"
              :footer-props="{
                'items-per-page-options': [15, 30, 50, 100],
                'items-per-page-text': $t('itemsPerPage'),
              }"
              class="text-no-wrap pointer"
              @update:items-per-page="setLimit"
              @pagination="handlePagination"
              @click:row="onEdit"
            >
              <template #[`header.trending`]>
                <v-icon size="22">
                  {{ icons.mdiTrendingUp }}
                </v-icon>
              </template>

              <template #[`item.providerName`]="{ item }">
                <span class="text-no-wrap th">{{ item.providerName }}</span>
              </template>

              <template #[`item.providerContribution`]="{ item }">
                <span class="text-no-wrap th">{{ item.providerContribution }}</span>
              </template>

              <template #[`item.actions`]="{ item }">
                <div class="d-flex align-center justify-center">
                  <!-- delete -->

                  <!--<v-tooltip bottom>-->
                  <!--<template #activator="{ on, attrs }">-->
                  <!--<v-btn-->
                  <!--icon-->
                  <!--small-->
                  <!--v-bind="attrs"-->
                  <!--@click="onDelete(item.id)"-->
                  <!--v-on="on"-->
                  <!--&gt;-->
                  <!--<v-icon size="18">-->
                  <!--{{ icons.mdiDeleteOutline }}-->
                  <!--</v-icon>-->
                  <!--</v-btn>-->
                  <!--</template>-->
                  <!--<span>{{ $t("delete") }}</span>-->
                  <!--</v-tooltip>-->

                  <v-tooltip bottom>
                    <template #activator="{ on, attrs }">
                      <v-btn icon small v-bind="attrs" @click="onEdit(item)" v-on="on">
                        <v-icon size="18">
                          {{ icons.mdiPencil }}
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t('Edit') }}</span>
                  </v-tooltip>
                </div>
              </template>

              <template slot="no-data">
                <div class="my-5">
                  <p>{{ $t('myBavNoResults') }}</p>
                </div>
              </template>
            </v-data-table>
          </v-card>
        </div>
      </div>
    </div>

    <ModalWindow :is-open="deleteProviderModal" error>
      <template #content>
        <p class="text-base">
          {{ $t('doYouWishToContinue') }}
        </p>
      </template>
      <template #actions>
        <v-btn outlined color="primary" @click="resetProviderToDelete">
          {{ $t('buttonCancel') }}
        </v-btn>
        <v-btn color="primary" @click="confirmDelete">
          {{ $t('buttonConfirm') }}
        </v-btn>
      </template>
    </ModalWindow>
    <ModalWindow :is-open="editProviderModal" :title="$t('Edit')" hasForm>
      <template #content>
        <v-form
          v-if="providerToEdit"
          id="editHealthInsuranceProviderForm"
          ref="editHealthInsuranceProviderForm"
          v-model="isFormValid"
          @submit.prevent="editHealthInsuranceProvider()"
        >
          <v-row class="justify-center px-3">
            <v-col cols="12" md="12" class="px-0">
              <v-text-field
                v-model="providerToEdit.providerName"
                :label="$t('Provider name')"
                :placeholder="$t('Provider name')"
                :rules="[validators.required]"
                class="me-3 mb-0"
                dense
                outlined
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="12" class="px-0">
              <v-text-field
                v-model="providerToEdit.providerContribution"
                type="number"
                min="0"
                oninput="if(this.value < 0) this.value = 0;"
                :rules="[validators.numberRequired]"
                :label="$t('Provider contribution')"
                :placeholder="$t('Provider contribution')"
                class="me-3 mb-0"
                dense
                outlined
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </template>
      <template #actions>
        <v-btn outlined color="primary" @click="resetProviderToEdit">
          {{ $t('buttonCancel') }}
        </v-btn>
        <v-btn color="primary" type="submit" form="editHealthInsuranceProviderForm">
          {{ $t('buttonConfirm') }}
        </v-btn>
      </template>
    </ModalWindow>
  </div>
</template>

<script>
import { ref, computed, getCurrentInstance, watch } from '@vue/composition-api';
import { mdiDeleteOutline, mdiPencil } from '@mdi/js';
import ModalWindow from '@/components/modal/ModalWindow';
import { required, numberRequired } from '../../../@core/utils/validation';
import {
  getHealthInsuranceProviders,
  createHealthInsuranceProvider,
  deleteHealthInsuranceProvider,
  updateHealthInsuranceProvider,
} from '../../../api/health-insurance';

export default {
  name: 'customerHealthInsuranceProviders',
  components: { ModalWindow },
  setup() {
    const vm = getCurrentInstance().proxy;

    const addProviderForm = ref(null);
    const editHealthInsuranceProviderForm = ref(null);
    const isFormValid = ref(false);

    const providerToDelete = ref(null);
    const deleteProviderModal = ref(false);

    const providerToEdit = ref(null);
    const editProviderModal = ref(false);

    const loadedHealthProviders = ref([]);
    const totalLoadedHealthProviders = ref(0);
    const loadingHealthProviders = ref(false);

    const limit = ref(15);
    const offset = ref(0);
    const currentPage = ref(1);
    const searchQuery = ref('');

    const newHealthProvider = ref('');
    const newHealthProviderContribution = ref('');
    const creatingNewHealthProvider = ref(false);

    const headers = computed(() => {
      return [
        {
          text: vm.$t('Provider name'),
          value: 'providerName',
          sortable: false,
          class: 'text-uppercase',
        },
        {
          text: vm.$t('Contribution, %'),
          value: 'providerContribution',
          align: 'center',
          sortable: false,
          class: 'text-uppercase',
        },
        {
          text: vm.$t('tableHeaderActions'),
          value: 'actions',
          align: 'center',
          sortable: false,
          class: 'text-uppercase',
        },
      ];
    });

    const addNewHealthInsuranceProvider = () => {
      const valid = addProviderForm.value.validate();
      if (!valid) return;

      creatingNewHealthProvider.value = true;

      createHealthInsuranceProvider(newHealthProvider.value, newHealthProviderContribution.value)
        .then(() => {
          addProviderForm.value.reset();
          newHealthProviderContribution.value = '';
          window.document.activeElement.blur();

          vm.$store.commit('showMessage', {
            text: 'Success',
            color: '#4caf50',
            timeout: '1000',
          });
        })
        .finally(() => {
          creatingNewHealthProvider.value = false;
          fetchAll();
        });
    };

    const fetchAll = () => {
      loadingHealthProviders.value = true;
      getHealthInsuranceProviders(offset.value, limit.value, searchQuery.value).then((res) => {
        loadingHealthProviders.value = false;
        loadedHealthProviders.value = res.data.page.reverse();
        totalLoadedHealthProviders.value = res.data.total;
      });
    };

    const handlePagination = (event) => {
      if (currentPage.value < event.page) offset.value += limit.value;
      if (currentPage.value > event.page) offset.value -= limit.value;

      currentPage.value = event.page;
      fetchAll();
    };

    const setLimit = (v) => {
      limit.value = v;
      currentPage.value = 1;
      offset.value = 0;
      fetchAll();
    };

    const onDelete = (id) => {
      providerToDelete.value = id;
      deleteProviderModal.value = true;
    };

    const onEdit = (provider) => {
      providerToEdit.value = provider;
      editProviderModal.value = true;
    };

    const resetProviderToDelete = () => {
      providerToDelete.value = null;
      deleteProviderModal.value = false;
    };

    const resetProviderToEdit = () => {
      providerToEdit.value = null;
      editProviderModal.value = false;
    };

    const confirmDelete = () => {
      deleteHealthInsuranceProvider(providerToDelete.value)
        .then(() => {
          resetProviderToDelete();
        })
        .finally(() => {
          fetchAll();
          vm.$store.commit('showMessage', {
            text: 'Success',
            color: '#4caf50',
            timeout: '1000',
          });
        });
    };

    const editHealthInsuranceProvider = () => {
      const valid = editHealthInsuranceProviderForm.value.validate();
      if (!valid) return;

      if (!providerToEdit.value.providerContribution) providerToEdit.value.providerContribution = 0;
      updateHealthInsuranceProvider(
        providerToEdit.value.id,
        providerToEdit.value.providerName,
        providerToEdit.value.providerContribution,
      ).finally(() => {
        resetProviderToEdit();
      });
    };

    watch(searchQuery, () => {
      fetchAll();
    });

    fetchAll();

    return {
      addProviderForm,
      editHealthInsuranceProviderForm,
      isFormValid,
      loadedHealthProviders,
      totalLoadedHealthProviders,
      loadingHealthProviders,
      searchQuery,
      headers,
      newHealthProviderContribution,
      newHealthProvider,
      creatingNewHealthProvider,
      deleteProviderModal,
      editProviderModal,
      providerToEdit,
      handlePagination,
      setLimit,
      addNewHealthInsuranceProvider,
      onDelete,
      onEdit,
      resetProviderToDelete,
      confirmDelete,
      editHealthInsuranceProvider,
      resetProviderToEdit,

      validators: { required, numberRequired },
      icons: { mdiDeleteOutline, mdiPencil },
    };
  },
};
</script>

<style scoped></style>

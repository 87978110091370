import axios from '@/plugins/axios';

const buildQuery = (offset, limit, search) => {
  let query = '';

  if (offset) {
    query += `&offset=${offset}`;
  }

  if (limit) {
    query += `&limit=${limit}`;
  }

  if (search) {
    query += `&searchString=${search}`;
  }

  return query;
};

export const getHealthInsuranceProviders = (offset = 0, limit = 15, search) => {
  const q = buildQuery(offset, limit, search);
  return axios.get(`/insurance?${q}`);
};

export const createHealthInsuranceProvider = (providerName, providerContribution) => {
  return axios.post(`/insurance`, { providerName, providerContribution });
};

export const deleteHealthInsuranceProvider = (id) => {
  return axios.delete(`/insurance/${id}`);
};

export const updateHealthInsuranceProvider = (id, providerName, providerContribution) => {
  return axios.put(`/insurance/${id}`, { providerName, providerContribution });
};

export const getHealthInsuranceProviderById = (id) => {
  return axios.get(`/insurance/${id}`);
};

<template>
  <v-container fluid>
    <v-form
      id="exampleCalculationsInUseForm"
      ref="exampleCalculationsInUseForm"
      v-model="isFormValid"
      @submit.prevent="onSubmit"
    >
      <v-text-field
        v-model="searchQuery"
        single-line
        dense
        outlined
        hide-details
        clearable
        :label="$t('formPlaceholderSearch')"
        :placeholder="$t('formPlaceholderSearch')"
        class="mb-5 me-3"
      ></v-text-field>
      <v-checkbox
        v-for="item in loadedCompanies"
        :key="item.id"
        v-model="selected"
        :label="item.companyName"
        :value="item.id"
        hide-details
      ></v-checkbox>
    </v-form>
  </v-container>
</template>
<script>
import { getCurrentInstance, ref, watch } from '@vue/composition-api';
import { getOrgTemplateUsage, removeOrgTemplateForCompany, useOrgTemplateForCompany } from '@/api/companies';
import { getCompaniesByOrganizationId } from '@/api/organizations';

export default {
  props: ['calculationId', 'orgId'],
  setup(props) {
    const vm = getCurrentInstance().proxy;
    const selected = ref([]);
    const inUseStash = ref([]);
    const loadedCompanies = ref([]);
    const searchQuery = ref('');
    const exampleCalculationsInUseForm = ref(null);
    const isFormValid = ref(false);

    const assignForUsage = (companyId) => {
      useOrgTemplateForCompany(companyId, props.calculationId);
    };

    const removeFromUsage = (companyId) => {
      removeOrgTemplateForCompany(companyId, props.calculationId);
    };

    const onSubmit = () => {
      const toAssign = selected.value.filter((c) => !inUseStash.value.includes(c));
      const toRemove = inUseStash.value.filter((c) => !selected.value.includes(c));

      toAssign.forEach((i) => assignForUsage(i));
      toRemove.forEach((i) => removeFromUsage(i));

      vm.$emit('submit');
    };

    const fetchCompanies = () => {
      getOrgTemplateUsage(props.calculationId).then((res) => {
        inUseStash.value = res.data.companyIds;
        selected.value = res.data.companyIds;
      });
      getCompaniesByOrganizationId(0, 0, searchQuery.value, props.orgId).then((res) => {
        loadedCompanies.value = res.data.page;
      });
    };

    fetchCompanies();

    watch(searchQuery, () => {
      fetchCompanies();
    });

    return { selected, loadedCompanies, searchQuery, onSubmit, isFormValid, exampleCalculationsInUseForm };
  },
};
</script>

<style scoped lang="scss"></style>

<template>
  <div class="py-5 border-top">
    <v-card>
      <v-card-text class="d-flex align-center flex-wrap pb-0">
        <v-row class="px-3">
          <v-col cols="12" md="4" class="px-0">
            <v-text-field
              v-model="searchQuery"
              single-line
              dense
              outlined
              hide-details
              clearable
              :label="$t('formPlaceholderSearch')"
              :placeholder="$t('formPlaceholderSearch') + 'organization'"
              class="mb-5 me-3"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <v-data-table
        :headers="headers"
        :items="loadedOrganizations"
        :server-items-length="totalOrganizations"
        :loading="loadingOrganizations"
        :footer-props="{
          'items-per-page-options': [5, 10, 15],
          'items-per-page-text': $t('itemsPerPage'),
        }"
        class="text-no-wrap pointer"
        @update:items-per-page="setLimit"
        @pagination="handlePagination"
      >
        <template #[`header.trending`]>
          <v-icon size="22">
            {{ icons.mdiTrendingUp }}
          </v-icon>
        </template>

        <template #[`item.organizationName`]="{ item }">
          <div class="py-5">
            <div class="text-no-wrap text--bold th mb-2">{{ item.organizationName }}</div>
            <p v-if="!item.examples.length">No templates found</p>
            <v-card v-else>
              <v-simple-table dense>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">Example calculations title</th>
                      <th class="text-left">ID</th>
                      <th class="text-left">In use</th>
                      <th class="text-left"></th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr
                      v-for="example in item.examples"
                      :key="example.name"
                      class="cursor-pointer"
                      @click="onEdit(example)"
                    >
                      <td>{{ example.name }}</td>
                      <td>{{ example.id }}</td>
                      <td @click.stop="onUsageClick(item.id, example.id)">
                        <a href="#">{{ example.inUse }}</a>
                      </td>
                      <td>
                        <div class="d-flex align-center justify-center">
                          <v-tooltip bottom>
                            <template #activator="{ on, attrs }">
                              <v-btn icon small v-bind="attrs" @click="onEdit(example)" v-on="on">
                                <v-icon size="18">
                                  {{ icons.mdiPencilOutline }}
                                </v-icon>
                              </v-btn>
                            </template>
                            <span>{{ $t('edit') }}</span>
                          </v-tooltip>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card>
            <v-btn v-if="item.examples.length < 3" class="mt-2 primary" @click="initTemplateCreation(item.id)"
              >Create template</v-btn
            >
          </div>
        </template>

        <template #[`item.id`]="{ item }">
          <span class="text-no-wrap th">{{ item.id }}</span>
        </template>

        <template slot="no-data">
          <div class="my-5">
            <p>{{ $t('myBavNoResults') }}</p>
          </div>
        </template>
      </v-data-table>
    </v-card>
    <ModalWindow :is-open="assignCompaniesModal" title="Create calculation" has-form>
      <template #content>
        <example-calculations-in-use
          v-if="assignCompaniesModal"
          :org-id="orgToEdit"
          :calculation-id="selectedTemplate"
          @submit="finishAssignment"
        />
      </template>
      <template #actions>
        <v-btn outlined color="primary" @click="finishAssignment">
          {{ $t('buttonCancel') }}
        </v-btn>
        <v-btn color="primary" type="submit" form="exampleCalculationsInUseForm">
          {{ $t('buttonConfirm') }}
        </v-btn>
      </template>
    </ModalWindow>

    <ModalWindow
      :is-open="createCalculationModal"
      title="Create calculation"
      :isFullScreen="true"
      :isFullScreenFixed="true"
    >
      <template #content>
        <ExampleCalculationsForm
          v-if="createCalculationModal"
          class="mt-15 mb-10"
          formId="exampleCalculationsForm"
          is-template
          :org-id="orgToEdit"
          :excludeCalculations="calculationsToExclude"
          @created="onCreatedCalculation"
        />
      </template>
      <template #actionsFullScreenClose>
        <v-btn icon dark @click="reset">
          <v-icon>{{ icons.mdiClose }}</v-icon>
        </v-btn>
      </template>
      <template #actionsFullScreenSave>
        <v-btn dark text class="my-auto" type="submit" form="exampleCalculationsForm"> Create </v-btn>
      </template>
    </ModalWindow>

    <ModalWindow
      :is-open="editCalculationModal"
      title="Edit calculation"
      :isFullScreen="true"
      :isFullScreenFixed="true"
    >
      <template #actionsFullScreenClose>
        <v-btn
          icon
          dark
          @click="
            editCalculationModal = false;
            calculationToEdit = null;
          "
        >
          <v-icon>{{ icons.mdiClose }}</v-icon>
        </v-btn>
      </template>
      <template #actionsFullScreenSave>
        <v-btn dark text class="my-auto" type="submit" form="exampleCalculationsForm"> Save </v-btn>
      </template>
      <template #content>
        <ExampleCalculationsForm
          class="mt-15 mb-10"
          :isEdit="true"
          :calculationToEdit="calculationToEdit"
          @created="onEditedCalculation"
        />
      </template>
    </ModalWindow>
  </div>
</template>

<script>
import { computed, getCurrentInstance, ref, watch } from '@vue/composition-api';
import { mdiDeleteOutline, mdiPencilOutline, mdiClose } from '@mdi/js';
import { getExampleCalculationsTemplates } from '@/api/companies';
import { getOrganizationsList, getOrgExampleCalculationTemplates } from '@/api/organizations';
import ExampleCalculationsForm from '@/components/example-calculations/ExampleCalculationsForm.vue';
import ExampleCalculationsInUse from '@/components/example-calculations/ExampleCalculationsInUse.vue';
import ModalWindow from '@/components/modal/ModalWindow.vue';

export default {
  name: 'commonExampleCalculations',
  components: { ModalWindow, ExampleCalculationsForm, ExampleCalculationsInUse },
  setup() {
    const vm = getCurrentInstance().proxy;

    const loadedOrganizations = ref([]);
    const totalOrganizations = ref(0);
    const loadingOrganizations = ref(false);
    const createCalculationModal = ref(false);
    const editCalculationModal = ref(false);
    const assignCompaniesModal = ref(false);
    const calculationToEdit = ref(null);
    const orgToEdit = ref(null);
    const selectedTemplate = ref(null);
    const calculationsToExclude = ref([]);

    const limit = ref(5);
    const offset = ref(0);
    const currentPage = ref(1);
    const searchQuery = ref('');

    const headers = computed(() => {
      return [
        {
          text: 'Organization',
          value: 'organizationName',
          sortable: false,
          class: 'text-uppercase',
        },
      ];
    });

    const fetchAll = () => {
      loadingOrganizations.value = true;
      getOrganizationsList(offset.value, limit.value, searchQuery.value).then(async (res) => {
        loadedOrganizations.value = await Promise.all(
          res.data.page.map(async (org) => {
            try {
              const examples = await getOrgExampleCalculationTemplates(org.id);
              return { ...org, examples: examples.data };
            } catch (e) {
              return org;
            }
          }),
        );
        loadingOrganizations.value = false;
        totalOrganizations.value = res.data.total;
      });
    };

    const handlePagination = (event) => {
      if (currentPage.value < event.page) offset.value += limit.value;
      if (currentPage.value > event.page) offset.value -= limit.value;

      currentPage.value = event.page;
      fetchAll();
    };

    const setLimit = (v) => {
      limit.value = v;
      currentPage.value = 1;
      offset.value = 0;
      fetchAll();
    };

    const onEditedCalculation = () => {
      calculationToEdit.value = null;
      editCalculationModal.value = false;
      fetchAll();
      vm.$store.commit('showMessage', {
        text: 'Success: Edited calculation',
        color: 'success',
        timeout: '10000',
      });
    };

    const onCreatedCalculation = () => {
      orgToEdit.value = null;
      createCalculationModal.value = false;
      fetchAll();
      vm.$store.commit('showMessage', {
        text: 'Success: Created calculation',
        color: 'success',
        timeout: '10000',
      });
    };

    const onEdit = (item) => {
      calculationToEdit.value = item;
      editCalculationModal.value = true;
    };

    const onUsageClick = (orgId, exampleId) => {
      orgToEdit.value = orgId;
      selectedTemplate.value = exampleId;
      assignCompaniesModal.value = true;
    };

    const finishAssignment = () => {
      orgToEdit.value = null;
      selectedTemplate.value = null;
      assignCompaniesModal.value = false;

      fetchAll();
    };

    const initTemplateCreation = (orgId) => {
      orgToEdit.value = orgId;
      createCalculationModal.value = true;
    };

    const reset = () => {
      orgToEdit.value = null;
      createCalculationModal.value = false;
    };

    watch(searchQuery, () => {
      fetchAll();
    });

    fetchAll();

    return {
      headers,
      loadedOrganizations,
      totalOrganizations,
      loadingOrganizations,
      searchQuery,
      createCalculationModal,
      editCalculationModal,
      calculationsToExclude,
      calculationToEdit,
      assignCompaniesModal,
      orgToEdit,
      selectedTemplate,
      reset,
      setLimit,
      handlePagination,
      onEditedCalculation,
      onCreatedCalculation,
      onEdit,
      onUsageClick,
      initTemplateCreation,
      finishAssignment,

      icons: {
        mdiDeleteOutline,
        mdiPencilOutline,
        mdiClose,
      },
    };
  },
};
</script>

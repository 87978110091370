<template>
  <div class="mb-6 mb-sm-10 d-flex">
    <v-card
      flat
      class="d-flex items-center text-center drag-n-drop flex-grow-1"
      style="box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25) !important; width: 100%"
      :class="{ 'drag-n-drop__errors': alertError }"
    >
      <div
        @drop="drop"
        :class="[{ active: active }]"
        @dragover="active = true"
        @dragleave="active = false"
        @mouseleave="active = false"
        :key="rerenderKey"
        v-if="!alertError && !alertProgress && !isUploading"
        class="drag-n-drop__zone flex-grow-1"
      >
        <input
          type="file"
          multiple
          id="assetsFieldHandle"
          name="fields[assetsFieldHandle][]"
          class="hide-input"
          @change="onChange"
          ref="file"
          accept=".pdf,.jpg,.jpeg,.png,.xls,.xlsx"
        />
        <v-alert
          v-model="alertInfo"
          close-label="Close Alert"
          outlined
          class="drag-n-drop__errors px-5 pb-6 pt-4 mb-0 flex-grow-1"
          :class="alertInfo ? 'd-flex' : ''"
          color="primary"
          transition="null"
          v-if="alertInfo"
        >
          <div>
            <v-icon size="60" class="mb-1 d-none d-sm-inline-block" color="primary">{{ icons.mdiCloudUpload }}</v-icon>
            <v-icon size="50" class="mb-1 d-inline-block d-sm-none" color="primary">{{ icons.mdiCloudUpload }}</v-icon>
            <label for="assetsFieldHandle" class="block cursor-pointer upload-label">
              <p class="text-subtitle-2 mb-2 mb-sm-0" :class="{ 'px-4': $vuetify.breakpoint.smAndDown }">
                {{ $t('uploadDocumentIntro1') }}
                <a href="#" click.prevent="">{{ $t('browse') }}</a>
                {{ $t('uploadDocumentIntro2') }}
              </p>
              <p class="mb-0 text-subtitle-2">{{ $t('uploadDocumentFileTypes') }}</p>
            </label>
          </div>
        </v-alert>
      </div>
      <v-alert
        v-model="alertError"
        close-label="Close Alert"
        outlined
        class="drag-n-drop__errors px-4 pb-5 pt-4 mb-0 flex-grow-1"
        :class="alertError ? 'd-flex' : ''"
        type="warning"
        :icon="icons.mdiAlertOutline"
        transition="null"
        v-if="alertError"
      >
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="warning"
              icon
              x-small
              style="margin-top: 6px; margin-left: 4px"
              v-bind="attrs"
              v-on="on"
              dark
              fab
              @click="cancelUpload"
            >
              <v-icon size="24" dark>
                {{ icons.mdiClose }}
              </v-icon>
            </v-btn>
          </template>
          <span>{{ $t('uploadDocumentCancel') }}</span>
        </v-tooltip>
        <div
          v-for="(item, index) in failedUploads"
          :key="item.name"
          class="flex-column flex-grow-1"
          :class="index !== 0 ? 'd-none' : 'd-flex'"
        >
          <div v-if="index === 0" class="d-flex flex-column flex-grow-1 pr-5">
            <p class="text-left mb-2 font-weight-bold text-subtitle-2 text-sm-body-1">
              <span
                >{{ item.name }} {{ $t('uploadDocumentFailedAlert') }} {{ $t(item.failureReason)
                }}{{ $t('uploadDocumentFailedAlert2') }}</span
              >
            </p>

            <p class="text-subtitle-2 text-sm-body-1 mb-3 text-left">
              {{ $t('uploadDocumentSupport1') }}
              <a :disabled="!acceptedNecessary" @click="contactSupport"> {{ $t('uploadDocumentSupport2') }}</a
              >.
            </p>
            <v-spacer></v-spacer>
            <p class="text-base mb-0 text-left">
              <v-icon size="22" color="primary" class="mr-1">
                {{ icons.mdiRefresh }}
              </v-icon>
              <a class="text-left text-base mb-0 mr-5 text-subtitle-2 text-sm-body-1" @click="retryUpload">{{
                $t('uploadDocumentTryAgain')
              }}</a>
              <v-icon size="24" color="primary" class="mr-1 text-sm-body-1 text-subtitle-2">
                {{ icons.mdiSkipNext }}
              </v-icon>
              <a class="text-sm-body-1 text-subtitle-2" @click="skipFile(item, index)">{{
                $t('uploadDocumentSkip')
              }}</a>
            </p>
          </div>
        </div>
      </v-alert>

      <v-alert
        v-model="alertProgress"
        close-label="Close Alert"
        type="info"
        outlined
        class="drag-n-drop__progress px-4 pb-5 pt-4 mb-0 flex-grow-1"
        :class="alertProgress ? 'd-flex' : ''"
        @close="cancelUpload"
        @input="alertProgress = false"
      >
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"
              icon
              x-small
              style="margin-top: 6px; margin-left: 4px"
              v-bind="attrs"
              v-on="on"
              dark
              fab
              @click="cancelUpload"
            >
              <v-icon size="24" dark>
                {{ icons.mdiClose }}
              </v-icon>
            </v-btn>
          </template>
          <span>{{ $t('uploadDocumentCancel') }}</span>
        </v-tooltip>
        <div>
          <v-icon size="30" class="mb-1" color="primary">{{ icons.mdiUpload }}</v-icon>
          <label for="assetsFieldHandle" class="block cursor-pointer primary--text font-weight-bold">
            {{ $t('uploadDocumentUploading') }}
          </label>
        </div>
        <p class="text-subtitle-2 mb-0">
          {{ uploadedFilesCount }} of {{ uploadFilesCount }} {{ $t('uploadDocumentUploadingCount') }}
        </p>
      </v-alert>
      <v-progress-linear
        color="primary"
        absolute
        bottom
        buffer-value="0"
        :value="(100 / uploadFilesCount) * uploadedFilesCount"
        stream
        v-if="isUploading && !alertError"
      ></v-progress-linear>
    </v-card>

    <ModalWindow :is-open="uploadDocumentsModal" :title="$t('myBavUploadModalTitle')" :stacked="true" :maxWidth="400">
      <template #content>
        <div v-if="activeRole !== 'ROLE_EMPLOYEE'" :class="{ 'px-5': $vuetify.breakpoint.smAndDown }">
          <v-autocomplete
            v-model="selectedEmployee"
            :items="loadedEmployees"
            no-filter
            :loading="loadingEmployees"
            :search-input.sync="searchEmployeeQuery"
            item-text="item.personalDetails.firstName"
            item-value="item.id"
            :label="$t('formLabelShareWith')"
            :placeholder="$t('placeholderSearchEmployee')"
            outlined
            dense
            return-object
            hide-details
            v-if="!companiesOptions && uploadType !== 'customer' && uploadType !== 'shareduser'"
            class="mb-5"
            @change="canConfirm = true"
          >
            <template v-slot:selection="data">
              <template>
                <span> {{ data.item.personalDetails.firstName }}&nbsp;{{ data.item.personalDetails.lastName }} </span>
              </template>
            </template>
            <template v-slot:item="data">
              <template>
                <span v-if="data">
                  {{ data.item.personalDetails.firstName }}&nbsp;{{ data.item.personalDetails.lastName }}
                </span>
              </template>
            </template>
          </v-autocomplete>
        </div>
        <div
          v-for="(file, index) in documentsToUpload"
          :key="file.size + '' + index"
          :class="{ 'px-5': $vuetify.breakpoint.smAndDown }"
        >
          <div class="pb-0 text-left">
            <p>{{ file.source.name }}</p>
            <v-select
              v-model="file.category"
              :items="categoryOptions"
              :label="$t('myBavUploadModalCategoryLabel')"
              :placeholder="$t('myBavUploadModalCategoryLabel')"
              dense
              outlined
              hide-details
              class="mb-4"
              v-if="uploadType !== 'customer'"
            ></v-select>
            <v-select
              v-model="sharedWithcompany"
              :items="companiesOptions"
              :label="$t('formLabelShareWith')"
              :placeholder="$t('formLabelShareWith')"
              dense
              outlined
              hide-details
              v-if="companiesOptions && uploadType !== 'customer'"
              class="mb-2"
            ></v-select>
          </div>
        </div>
        <div v-if="activeRole === 'ROLE_EMPLOYEE'" :class="{ 'px-6': $vuetify.breakpoint.smAndDown }">
          <p class="text-left mb-2">
            {{ $t('myBavUploadModalShare') }}
            <v-tooltip bottom max-width="300">
              <template #activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on">{{ icons.mdiInformationOutline }}</v-icon>
              </template>
              <span>{{ $t('myBavUploadModalShareInfo') }}</span>
            </v-tooltip>
          </p>
          <v-switch v-model="grantHrAccessWithUpload" class="mt-0 pt-0" dense hide-details></v-switch>
        </div>
      </template>
      <template #actions>
        <v-btn type="submit" block color="primary" :disabled="!canConfirm" @click="confirmUpload">
          {{ $t('buttonConfirm') }}
        </v-btn>
        <v-btn
          outlined
          block
          @click="
            uploadDocumentsModal = false;
            documentsToUpload = [];
          "
          color="primary"
          class="ml-0 mt-3"
        >
          {{ $t('buttonCancel') }}
        </v-btn>
      </template>
    </ModalWindow>
  </div>
</template>
<script>
import {
  mdiCloudUpload,
  mdiUpload,
  mdiAlertOutline,
  mdiRefresh,
  mdiClose,
  mdiInformationOutline,
  mdiSkipNext,
} from '@mdi/js';
import { ref, getCurrentInstance, computed, watch } from '@vue/composition-api';
import ModalWindow from '@/components/modal/ModalWindow';
import { DOC_OTHER, MAX_FILE_SIZE } from '@/constants';
import { GRANT_DOCUMENT_ACCESS_TO_HR_ACTION } from '@/store/modules/documents/actions';
import { validateFileSize } from '@/utils/helpers';

export default {
  components: { ModalWindow },
  props: {
    uploadType: {
      type: String,
    },
    activeRole: {
      type: String,
    },
    employeeId: {
      type: String,
      default: '',
    },
    loadedEmployees: {
      type: Array,
    },
    categoryOptions: {
      type: Array,
    },
    companiesOptions: {
      type: Array,
    },
  },
  name: 'DragNDropUpload',

  setup(props) {
    const vm = getCurrentInstance().proxy;
    const alertInfo = ref(true);
    const alertError = ref(false);
    const alertProgress = ref(false);
    const rerenderKey = ref(0);
    const active = ref(false);
    const filelist = ref([]);
    const uploadErrors = ref([]);
    const documentsToUpload = ref([]);
    const uploadDocumentsModal = ref(false);
    const grantHrAccessWithUpload = ref(false);
    const selectedEmployee = ref(null);
    const isUploading = ref(false);
    const uploadFilesCount = ref(0);
    const uploadedFilesCount = ref(0);
    const failedUploads = ref([]);
    const loadingEmployees = computed(() => vm.$store.state.employees.loading);
    const searchEmployeeQuery = ref('');
    const { userData } = vm.$store.getters;
    const uploadType = ref(null);
    const uploadId = ref(null);
    const cancelRequest = computed(() => {
      return vm.$store.getters['upload/cancelRequest'];
    });
    const canConfirm = ref(true);
    const isCancelled = ref(false);
    const contactSupport = () => {
      if (props.activeRole === 'ROLE_ADMIN') {
        window.open('https://degura.atlassian.net/jira/software/projects/TECH/issues/');
      } else {
        vm.$userlike.userlikeStartChat();
      }
    };
    const acceptedNecessary = ref(vm.$store.getters.necessaryCookies);

    const onSearchEmployees = () => {
      vm.$store.dispatch('employees/search', {
        search: searchEmployeeQuery.value,
      });
    };
    const setUploadOptions = async () => {
      switch (props.uploadType) {
        case 'employee':
          uploadType.value = 'employee';
          uploadId.value = props.employeeId;
          break;
        case 'shared':
          uploadType.value = 'employee';
          uploadId.value = selectedEmployee.value.id;
          break;
        case 'shareduser':
          uploadType.value = 'employee';
          uploadId.value = props.employeeId;
          break;
        case 'company':
          uploadType.value = 'company';
          uploadId.value = sharedWithcompany.value;
          break;
        case 'customer':
          uploadType.value = 'customer';
          uploadId.value = '';
          break;
        default:
          uploadType.value = 'employee';
      }
    };
    const sharedWithcompany = ref(props.activeRole === 'ROLE_BAV_MANAGER' ? userData.data.companyId : '');

    const onFileListChange = (fileList) => {
      if (props.loadedEmployees && !selectedEmployee.value) {
        canConfirm.value = false;
      }
      uploadDocumentsModal.value = true;
      createUploadObjects(fileList);
    };

    const createUploadObjects = (files) => {
      documentsToUpload.value = [];
      grantHrAccessWithUpload.value = false;

      for (let i = 0; i < files.length; i++) {
        documentsToUpload.value[i] = {
          fileIndex: i,
          cancelled: false,
          category: DOC_OTHER,
          categorized: false,
          id: null,
          loaded: 0,
          failureReason: null,
          source: files[i],
          name: files[i].name,
        };
      }
    };

    const confirmUpload = async () => {
      isUploading.value = true;
      alertProgress.value = true;
      alertError.value = false;
      uploadFilesCount.value = documentsToUpload.value.length;
      uploadedFilesCount.value = 0;
      uploadDocumentsModal.value = false;
      failedUploads.value = [];
      isCancelled.value = false;

      const controller = await vm.$store.dispatch('upload/createController');

      try {
        await setUploadOptions();
        await Promise.allSettled(
          documentsToUpload.value.map(async (file, index) => {
            if (!validateFileSize(file.source, MAX_FILE_SIZE)) {
              file.failureReason = 'DOCUMENT_SIZE_INVALID';
              failedUploads.value.push(file);
              return;
            }

            const formData = new FormData();
            formData.append('file', file.source);
            formData.append(
              'document',
              JSON.stringify({
                [uploadType.value === 'employee' ? 'employeeId' : 'companyId']: uploadId.value,
                name: file.name,
                category: file.category,
                scope: uploadType.value.toUpperCase(),
                salesforceOnly: false,
                employeeOnly: false,
              }),
            );

            const payload = {
              formData,
              controller,
            };

            vm.$store.commit('upload/updateUploadState', {
              uploadQueue: documentsToUpload.value,
            });

            const res = await vm.$store.dispatch('upload/uploadDocuments', payload).catch((err) => {
              if (err.message === 'canceled') {
                file.failureReason = err.message;
              }

              failedUploads.value.push(file);
            });

            uploadedFilesCount.value++;

            if (index > -1) {
              documentsToUpload.value.splice(index, 1);
            }
            file.id = res.id;
            vm.$store.commit('upload/updateUploadState', {
              uploadQueue: documentsToUpload.value,
              uploadedData: file,
              totalProgress: (100 / uploadFilesCount.value) * uploadedFilesCount.value,
            });

            vm.$emit('fetchDocuments');
            setTimeout(() => {
              vm.$store.commit('upload/removeItem', res.id);
            }, 30000);

            if (grantHrAccessWithUpload.value) {
              vm.$store.dispatch(`documents/${GRANT_DOCUMENT_ACCESS_TO_HR_ACTION}`, res.id);
            }
            return res;
          }),
        );
      } catch (err) {
        failedUploads.value = documentsToUpload.value;
      } finally {
        if (!failedUploads.value.length && !isCancelled.value) {
          documentsToUpload.value = [];
          isUploading.value = false;
          alertProgress.value = false;
          vm.$store.commit('showMessage', {
            text: 'uploadDocumentSuccessMsg',
            color: 'success',
            timeout: '4000',
          });
          vm.$store.commit('upload/updateUploadState', {
            uploadQueue: documentsToUpload.value,
          });
        }
        if (isCancelled.value === false && failedUploads.value.length) {
          alertError.value = true;
          alertProgress.value = false;
          vm.$store.commit('upload/updateUploadState', { failedUploads: failedUploads.value });
          vm.$store.commit('showMessage', {
            text: 'uploadDocumentFailedMsg',
            color: 'error',
            timeout: '4000',
          });
        } else {
          vm.$store.commit('upload/updateUploadState', {
            uploadQueue: [],
            failedUploads: failedUploads.value,
            totalProgress: null,
          });
        }
      }
    };

    const skipFile = (item, index) => {
      if (index > -1) {
        failedUploads.value.splice(index, 1);
      }
      if (failedUploads.value.length === 0) {
        documentsToUpload.value = [];
        failedUploads.value = [];
        isUploading.value = false;
        alertProgress.value = false;
        alertError.value = false;
        vm.$store.commit('upload/updateUploadState', {
          uploadQueue: [],
          totalProgress: 0,
        });
      }
    };

    const cancelUpload = () => {
      vm.$store.commit('upload/cancelRequest');
    };

    const retryUpload = () => {
      documentsToUpload.value = failedUploads.value;
      confirmUpload();
    };

    const setCategory = () => {
      vm.$store.dispatch('documents/filterCategory', categoryFilter.value);
    };

    const onChange = () => {
      if (filelist.value.length >= 5) return;
      filelist.value = [...vm.$refs.file.files].slice(0, 5);

      onFileListChange(filelist.value);
      filelist.value = [];
      rerenderKey.value += 1;
    };

    const drop = (event) => {
      event.preventDefault();
      vm.$refs.file.files = event.dataTransfer.files;
      onChange();
    };

    watch(cancelRequest, (v) => {
      if (v === null) {
        isCancelled.value = true;
        isCancelled.value = true;
        alertError.value = false;
        alertProgress.value = false;
        isUploading.value = false;
        vm.$store.commit('showMessage', {
          text: 'uploadDocumentCanceledMsg',
          color: 'info',
          timeout: '4000',
        });
      }
    });

    watch(searchEmployeeQuery, () => {
      onSearchEmployees();
    });

    return {
      rerenderKey,
      active,
      filelist,
      uploadErrors,
      onChange,
      drop,
      confirmUpload,
      onFileListChange,
      setCategory,
      documentsToUpload,
      grantHrAccessWithUpload,
      uploadDocumentsModal,
      selectedEmployee,
      loadingEmployees,
      searchEmployeeQuery,
      onSearchEmployees,
      DOC_OTHER,
      isUploading,
      uploadFilesCount,
      uploadedFilesCount,
      alertError,
      alertProgress,
      cancelUpload,
      failedUploads,
      retryUpload,
      userData,
      sharedWithcompany,
      skipFile,
      alertInfo,
      cancelRequest,
      isCancelled,
      contactSupport,
      acceptedNecessary,
      canConfirm,

      icons: {
        mdiCloudUpload,
        mdiUpload,
        mdiAlertOutline,
        mdiRefresh,
        mdiClose,
        mdiInformationOutline,
        mdiSkipNext,
      },
    };
  },
};
</script>

<style lang="scss">
.drag-n-drop {
  min-height: 152px !important;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25) !important;
  border-radius: 3px !important;
  border: none !important;
  .v-alert {
    border-radius: 3px !important;
    &:before {
      background-color: white !important;
    }
    p {
      color: #505a68;
      &.text-sm-body-1 {
        line-height: 1.3 !important;
        letter-spacing: normal !important;
      }
    }
  }
  &__errors,
  &__progress {
    .v-alert__wrapper {
      flex: 1 !important;
      align-items: flex-start;
    }

    background-color: white;
    .v-alert__dismissible {
      position: absolute;
      top: 25px;
      right: 20px;
    }
    .v-alert__content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex: 1;
      height: 100%;
    }
    .v-btn {
      position: absolute;
      top: 4px;
      right: 10px;
    }
  }
  &__progress {
    .v-alert__icon {
      display: none;
    }
  }
  &__zone {
    transition: all ease-in-out 0.3s;
    display: flex;
    &:hover,
    &.active {
      background: rgb(243, 243, 243) !important;
    }
  }
  @media only screen and (max-width: 959px) {
    .v-alert__icon {
      display: none;
    }
  }
}
.hide-input {
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 0;
  left: 0;
  top: 0;
  cursor: pointer;
  z-index: 1;
}
</style>
